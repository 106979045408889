import pilot from './sections/images/image 1.png'
import pilot1 from './sections/images/scott-graham-5fNmWej4tAA-unsplash.jpg'
import pilot2 from './sections/images/irwan-rbDE93-0hHs-unsplash.jpg'
import vista from './sections/images/iPad Pro 2020.png'
import vista1 from './sections/images/alexander-grey-tn57JI3CewI-unsplash (2).jpg'
import vista2 from './sections/images/louis-reed-pwcKF7L4-no-unsplash.jpg'
import beacon from './sections/images/Rectangle (1).png'
import beacon1 from './sections/images/national-cancer-institute-89rul39ox2I-unsplash.jpg'
import beacon2 from './sections/images/convertkit-F_UU_3vE4Lg-unsplash.jpg'
import zenith from './sections/images/Rectangle (2) (1).png'
import zenith1 from './sections/images/steve-johnson-_0iV9LmPDn0-unsplash.jpg'
import zenith2 from './sections/images/ux-indonesia-qC2n6RQU4Vw-unsplash.jpg'
import echo from './sections/images/iPhone 13.png'
import echo1 from './sections/images/firmbee-com-SpVHcbuKi6E-unsplash (1).jpg'
import echo2 from './sections/images/national-cancer-institute-L8tWZT4CcVQ-unsplash.jpg' 
import journey from './sections/images/MacBook Pro 16 (1).png'
import journey1 from './sections/images/stellrweb-djb1whucfBY-unsplash.jpg'
import journey2 from './sections/images/andrea-de-santis-zwd435-ewb4-unsplash (2).jpg'
import sites_1 from './sections/images/Screenshot 2024-01-21 at 6.41.43 PM.png'
import sites_2 from './sections/images/Screenshot 2024-01-21 at 6.43.05 PM.png'
import sponsors_1 from './sections/images/Screenshot 2024-01-21 at 7.05.42 PM.png'
import sponsors_2 from './sections/images/Screenshot 2024-01-21 at 7.06.01 PM.png'
import cros_1 from './sections/images/Screenshot 2024-01-21 at 7.12.31 PM.png'
import cros_2 from './sections/images/Screenshot 2024-01-21 at 7.12.42 PM.png'
import cros_3 from './sections/images/hans-reniers-lQGJCMY5qcM-unsplash (1).jpg'
import banner from './sections/images/pexels-tirachard-kumtanom-733856 (1).jpg'
import banner2 from './sections/images/national-cancer-institute-KMvoHcB-w5g-unsplash (1).jpg'
import banner3 from './sections/images/piron-guillaume-U4FyCp3-KzY-unsplash (1).jpg'




export const products = [
    {
        name: 'Pilot', sub: "eCTMRS", color: 'radial-gradient(at top left, #28D9EC, #4C6C72, #E2BAC1)',
        img1: pilot,
        img2: pilot1,
        img3: pilot2,
        summary: "A flagship product, for a flagship site.", sub_summary: "It all begins with your records. Our eCTMRS tool is the first step in housing all your records in one place so data can be shared across our suite. This isn't just any medical record system, it is THE clinical trial medical record system.",
        text1: "Upload and facilitate the storage, organization, and retrieval of medical records pertinent to clinical trials. Streamline data management, improve accessibility, and enhance accuracy while ensuring compliance with regulatory requirements.",
        text2: "Pilot's innovative proprietary smart scanner hardware seamlessly uploads paper records directly into our cloud-based platform, ensuring efficient, streamlined digital record management for real-time access.",
        text3: "For EMR forward sites, our platform allows for digital uploads into the cloud allowing all pertinent medical records to be accessible in real-time for update, review and verification."
    },

    {
        name: 'Vista', sub: "eSOURCE", color: 'radial-gradient(at top left, #782D39, #CDC489, #6A697D)',
        img1: vista,
        img2: vista1,
        img3: vista2,
        summary: "Ditch the papers, and ditch the data entry.", sub_summary: "Our eSource tool speaks directly with our eCTMRS, EDC and ePRO/eCOA. No more papers. Way less data entry.",
        text1: "Custom built eSource forms that replace traditional paper-based methods, allowing investigators and third party auditors to directly access information in real-time.",
        text2: "Vista is the next generation eSource that connects directly to the eCTMRS system and EDC allowing an automated transfer of data between systems. ",
        text3: "Whether stand-alone or within the integrated suite, Vista allows the digitization of your clinical site’s clinical trial records and data without sacrificing ease of use."
    },

    {
        name: 'Beacon', sub: "eISF/eTMF", color: 'radial-gradient(at top left, #7CCF9B, #586B03, #B8367D)',
        img1: beacon,
        img2: beacon1,
        img3: beacon2,
        summary: "A regulatory file everyone can contribute to.", sub_summary: "Our eISF/eTMF system allows all stakeholders to contribute and maintain pertinent regulatory records adding accuracy and efficiency to your most important regulatory documents.",
        text1: "Beacon provides a tailored, comprehensive repository for storing all regulatory documents crucial to your clinical trial's conduct. This custom-built solution caters specifically to your trial's needs, ensuring all essential documents are organized and easily accessible.",
        text2: "With Beacon, all clinical trial-related documentation can be housed in a single, centralized location. This unified approach is facilitated through an intuitive dashboard, simplifying access and management of critical trial documents.",
        text3: "The introduction of Beacon eliminates the need for third-party document management systems. This integration streamlines document handling processes, enhancing efficiency and reducing the complexity of managing clinical trial documentation."
    },

    {
        name: 'Zenith', sub: "EDC", color: 'radial-gradient(at top left, #A9E21B, #4E74CC, #ABC481)',
        img1: zenith,
        img2: zenith1,
        img3: zenith2,
        summary: "AI-powered EDC, no more tedious data entry.", sub_summary: "An EDC that takes information directly from our eCTMRS making manual data entry a thing of the past.",
        text1: "Clincove's Zenith EDC system, powered by AI, revolutionizes data management in clinical trials by integrating data directly from eCTMRS into the EDC. This reduces manual data entry significantly, enhancing both efficiency and accuracy.",
        text2: "The automation in Zenith EDC accelerates data capture while ensuring precision, as AI algorithms diligently detect and correct errors. This leads to improved data reliability and quality in clinical trial processes.",
        text3: "Clincove’s platform simplifies and optimizes electronic data capture, offering a streamlined, user-friendly interface. This innovation enhances the efficiency of clinical trial workflows, allowing for a more focused approach to research."
    },

    {
        name: 'Echo', sub: "ePRO & eCOA", color: 'radial-gradient(at top left, #EF74BE, #B7A77D, #3E3FB4)',
        img1: echo,
        img2: echo1,
        img3: echo2,
        summary: "User friendly ePRO/eCOA solutions that your patients will actually use.", sub_summary: "Our ePRO/eCOA solutions are easy to use, tablet, phone, computer friendly and most importantly, directly connected to EDC.",
        text1: "Echo, Clincove's innovative ePRO and eCOA solution, is custom-built to gather patient-reported data and clinical outcome measurements. It enhances accuracy, efficiency, and patient engagement through its compatibility with smartphones, tablets, or computers.",
        text2: "Echo stands out as the next-generation tool for at-home patient data collection. It seamlessly connects to the eCTMRS system and EDC, facilitating an automated and efficient transfer of data between systems.",
        text3: "This integration not only streamlines the data collection process but also ensures real-time data accuracy and reliability. Echo's ability to link directly with core clinical systems simplifies the entire workflow, making it a valuable asset in modern clinical trials."
    },

    {
        name: 'Journey', sub: "Budget Assist", color: 'radial-gradient(at top left, #CF4725, #90A90E, #9213D6)',
        img1: journey,
        img2: journey1,
        img3: journey2,
        summary: "Never be out of step with the market again.", sub_summary: "Maximize revenues while remaining within market ranges. Our Budget assist tool will tell you exactly how much each procedure worth. No more guessing.",
        text1: "Journey, Clincove's AI-powered budget assistant tool, is designed to maximize revenue within market values for all clinical and administrative tasks in clinical research. This advanced tool aids in efficient financial planning and management.",
        text2: "Clincove’s platform, through Journey, focuses on building revenue-maximized budgets by utilizing reliable financial data. This approach ensures financial success and strategic allocation of every dollar for maximum returns in clinical research projects.",
        text3: "The integration of AI in Journey enables precise financial forecasting and decision-making. It brings a new level of financial intelligence to clinical research, ensuring that resources are optimally utilized for the best possible outcomes."
    },


];

export const solutions = [
    {
        name: 'Sites',
        title: "Clinical Sites",
        img1: sites_1,
        img2: sites_2,
        banner: banner3,
        mission: 'A cutting-edge solution for clinical trial sites that revolutionizes efficiency and efficacy.',
        summary: "Clinical sites can greatly benefit from utilizing Clincove, a sophisticated clinical trial operating system", 
        text1: "Today’s clinical research landscape is highly competitive, especially for novel therapeutics. With our clinical trial OS, sites can stand above the rest by utilizing tools that both benefit their operations and increase value for CROs and sponsors looking for modern and efficient sites.",
        text2: "Automating various tasks and workflows, the system minimizes manual labor and associated errors, eliminating the need for extensive administrative staff thereby reducing costs and increasing quality across site activities.",
        text3: "This innovative platform offers a seamless and centralized solution for managing all aspects of clinical trials.",
        text4: "From patient recruitment and enrollment to data collection and analysis, Clincove streamlines processes, enhances communication, and ensures compliance with regulatory requirements.",
        text5: "Its user-friendly interface empowers clinical sites to efficiently coordinate with sponsors, monitor trial progress, and make informed decisions in real-time."

    },
    {
        name: 'Sponsors',
        title: "Sponsors",
        img1: sponsors_1,
        img2: sponsors_2,
        banner: banner2,
        mission: "A transformative technology ally for sponsors, ensuring seamless and successful clinical trial management.",
        summary: "A true technological and operational partner, harnessing the capabilities of a cutting-edge clinical trial operating system", 
        text1: "The need for a technology driven  partner that understands the research landscape from all ends is more necessary than ever. At Clincove, we have positioned ourselves to be at the forefront of practical and achievable innovation.",
        text2: "With Clincove’s technological and operational expertise, we can take any project from planning to execution. Sponsors can rest knowing that any technological or operational challenges are surmountable when it comes to their clinical trials.",
        text3: "This advanced and powerful platform empowers sponsors to oversee all aspects of trial operations seamlessly.",
        text4: "From study planning and site selection to monitoring and data analysis, Clincove provides sponsors with real-time insights and tools to ensure efficient study execution.",
        text5: "Its user-friendly interface facilitates communication with clinical sites and CROs, enabling proactive decision-making and streamlined collaboration."

    },
    {
        name: 'cros',
        title: "Clinical Research Organizations (CROs)",
        img1: cros_1,
        img2: cros_2,
        img3: cros_3,
        banner: banner,
        mission: "An all-encompassing platform for CROs, enhancing efficiency and collaboration in clinical trial management.",
        summary: "Clinical research organizations (CROs) can significantly enhance their capabilities and efficiency through the adoption of Clincove, a robust clinical trial operating system", 
        text1: "Our clinical trial Operating System (OS) aids Contract Research Organizations (CROs) of all sizes in streamlining the entire clinical trial process. It enables efficient management of multiple trials at once, optimizing each phase from start to finish for better productivity.",
        text2: "The system facilitates seamless communication and collaboration between various stakeholders, including sponsors, and study sites, reducing administrative burdens, review delay and quality degradation all while keeping costs down.",
        text3: "Real-time access to comprehensive data and analytics, empowers CROs to make informed decisions and provide the best possible service to their sites and sponsors.",
        text4: "This advanced platform offers a comprehensive suite of tools to manage every aspect of clinical trials. From protocol design and site selection to patient recruitment, data management, and reporting, Clincove provides a unified solution that streamlines workflows and promotes collaboration among teams.",
    },

]