import unified_platform from '../sections/images/unified-platform.png'
export const OurMission = () => {
    return (<>
        <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8" style={{ backgroundColor: "#F6F9FC", clipPath: "polygon(0 0, 100% 0, 100% 85%, 0% 100%, 0 100%)", borderTopColor: "white", borderTop: "1px solid white" }}>


            <div className="grid mt-0 lg:px-8 pb-2 md:pb-8 lg:px-8" >

                <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                    <h2 class="purple">
                        Our Mission
                    </h2>
                </div>

                <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                    <h2>
                        “Breaking down barriers that hinder the progress of medical research, ensuring that every patient has the opportunity to contribute to advancements in healthcare”

                    </h2>
                </div>



            </div>

        </div >
    </>
    )
}