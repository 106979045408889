import logo from '../sections/images/clincove.svg'

export const NotFound = () => {
    return (
        <div className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
            <div className="flex flex-column align-items-center justify-content-center">
                <img src={logo} alt="Sakai logo" className="mb-5 w-12rem flex-shrink-0" />
                <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(#6200EE 10%, rgba(98, 0, 238, 0) 30%)' }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style={{ borderRadius: '53px' }}>
                        <span className="font-bold text-3xl purple">
                            404
                        </span>
                        <h1 className="text-900 font-bold text-5xl mb-2">
                            Not Found
                        </h1>
                        {/* Link 1: Home */}
                        <a className="w-full flex align-items-center py-5 border-300 border-bottom-1" href="/" style={{ textDecoration: 'none' }}>
                            <span className="flex justify-content-center align-items-center border-round" style={{ height: '3.5rem', width: '3.5rem', background: 'white', border: "1px solid black" }}>
                                <i className="pi pi-home text-2xl" style={{ color: 'black' }}></i>
                            </span>
                            <span className="ml-4 flex flex-column">
                                <span className="text-900 lg:text-xl font-medium mb-1">
                                    Home
                                </span>
                                <span className="text-600 lg:text-lg">
                                    Discover our main page.
                                </span>
                            </span>
                        </a>
                        {/* Link 2: Contact Us */}
                        <a className="w-full flex align-items-center py-5 border-300 border-bottom-1" href="/contact-us" style={{ textDecoration: 'none' }}>
                            <span className="flex justify-content-center align-items-center border-round" style={{ height: '3.5rem', width: '3.5rem', background: 'white', border: "1px solid black" }}>
                                <i className="pi pi-fw pi-envelope text-2xl" style={{ color: 'black' }}></i>
                            </span>
                            <span className="ml-4 flex flex-column">
                                <span className="text-900 lg:text-xl font-medium mb-1">
                                    Contact Us
                                </span>
                                <span className="text-600 lg:text-lg">
                                    Get in touch for support and inquiries.
                                </span>
                            </span>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    );
};
