import { useParams } from "react-router-dom"
import { products } from "../constants"
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import temp from "./images/Background5.png";
import { Hero } from "./hero";
import { HeroNoText } from "./hero-no-text";
import { HeroProductSolution } from "./hero-product-solution";
export const Product_Info = () => {
    var product_name = useParams().product_name;
    const [product, setProduct] = useState({})
    const nav = useNavigate();
    
    useEffect(() => {
        const foundProduct = products.find(p => p.name.toLowerCase() === product_name.toLowerCase());

        if (foundProduct) {
            setProduct(foundProduct);
        } else {
            // Redirect to /404 if no product is found
            nav('/404');
        }
    }, [product_name, nav]);

    return (
        <>

            <HeroProductSolution />

            <div id="hero" className="py-0 lg:px-4 px-2 lg:px-1 2xl:px-8 mx-0 my-0 lg:mx-0" style={{ backgroundColor: "#F6F9FC" }}>
                <div className="grid mt-0 lg:px-1 2xl:px-8 pb-0 lg:px-1 2xl:px-8 md:pb-0">

                </div>

                <div className="grid pb-0 lg:px-1 2xl:px-8 md:pb-0">
                    <div className="col-12 lg:col-6  flex flex-column lg:align-items-end text-left lg:text-left lg:pr-4 xl:pr-8" style={{ paddingTop: "20px" }}>

                    </div>
                    <div className="col-12 lg:col-6"></div>
                    <div className="col-12 lg:col-6 my-auto flex flex-column lg:align-items-end text-left lg:text-left lg:pr-4 xl:pr-8">

                        <div className="col-12 lg:col-10 ">

                            <div className="text-700 text-xl line-height-3 mr-0 md:mr-2 ">

                                <h2 className="text-700 text-5xl line-height-3 mr-0 md:mr-2" style={{ paddingTop: "5px" }}>
                                    ({product.sub})
                                </h2>
                                <h2 className="line-height-1 text-900 text-3xl font-normal mb-4" style={{ marginTop: "0px" }}>{product.name}</h2>

                                <span>
                                    
                                    {product.text1}
                                 
                                </span>
                           
                            </div>
                     
                        </div>
                    </div>
                    <div className="flex items-center justify-content-left flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0 lg:pl-4 xl:pl-8">
                        <div className="col-12 items-center justify-center mt-8">
                            <img src={product.img1} className="lg:w-10 w-12 h-auto" alt="mockup" />
                        </div>
                    </div>


                </div>

                <div className="grid pb-0 lg:px-1 2xl:px-8 md:pb-0">

                    <div className="flex justify-content-center col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0 lg:pr-4 xl:pr-8" >
                        <div className="col-12 text-right">
                            <span>

                                <img src={product.img2} className="lg:w-10 w-12" alt="mockup" />
                            </span>
                        </div>
                    </div>
                    <div className="col-12 lg:col-6 my-auto flex flex-column lg:align-items-start text-left lg:text-left lg:pl-4 xl:pl-8" >

                        <div className="col-12 lg:col-10 ">

                            <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 " >
                                {product.text2}
                            </span>
                        </div>
                    </div>
                </div>


                <div className="grid  pb-0 lg:px-1 2xl:px-8 md:pb-0">
                    <div className="col-12 lg:col-6 my-auto flex flex-column lg:align-items-end text-left lg:text-left lg:pr-4 xl:pr-8" style={{ paddingTop: "20px" }}>

                        <div className="col-12 lg:col-10 ">
                            <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 ">
                                {product.text3}
                            </span>
                        </div>
                    </div>
                    <div className="flex justify-content-center flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0  lg:pl-4 xl:pl-8" >
                        <div className="col-12 text-left">
                            <span>

                                <img src={product.img3} className="lg:w-10 w-12" alt="mockup" />
                            </span>
                        </div>

                    </div>


                </div>


            </div>
        </>
    )

}