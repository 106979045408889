import { useNavigate } from 'react-router-dom'
import unified_platform from '../sections/images/unified-platform.png'
export const Guides = () => {
    const nav = useNavigate();
    return (<>

        <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8" style={{ backgroundColor: "#F6F9FC", clipPath: "polygon(0 0, 100% 0, 100% 85%, 0% 100%, 0 100%)", borderTopColor: "white", borderTop: "1px solid white" }}>


            <div className="grid mt-0 lg:px-8 pb-2 md:pb-8 lg:px-8" >


                <div className="col-12 lg:col-8 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 text-4xl green" >
                        <span className='purple text-4xl'>Guides</span>

                    </span>
                    <h2 className="line-height-1 text-900 text-4xl font-normal">System Specific User Guides</h2>
                </div>

                <div className="col-12 lg:col-8 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2">
                        Please contact us for user specific guides for our entire line-up of solutions.
                    </span>
                </div>


                <div className="col-12 lg:col-6 my-auto flex flex-column text-left lg:text-left lg:align-items-start" style={{ paddingTop: "40px" }}>
                    <div>

                        <button onClick={() => nav('/contact-us')} aria-label="Get Started" class="p-button p-component text-xl border-none mt-3 font-normal line-height-3 px-3 text-white" type="button" data-pc-name="button" data-pc-section="root" style={{ backgroundColor: "#6200EE", borderRadius: "52px" }} >
                            <span class="p-button-label p-c" data-pc-section="label">
                                Contact Us {' '}
                                <i className='pi pi-arrow-right'></i>
                            </span>


                        </button>

                    </div>

                </div>






            </div>

        </div >
    </>
    )
}