import hero from './images/hero.png'
import iPhone13 from './images/iPhone 13.png'
import { useNavigate } from 'react-router-dom'
export const Hero = () => {
    const nav = useNavigate();
    return(
    <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8">
            <div className="grid mt-0 lg:px-8 pb-2 md:pb-8 lg:px-8">
                <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                    <h1 className="line-height-1 text-900 font-normal">AI-powered clinical trial OS, for the modern clinical trial</h1>
                    <span className="text-700 text-xl line-height-3 mr-0 mt-5 mb-5 md:mr-2">
                        Clinical sites and sponsors of all sizes—from small biotech to large academic medical centers—use Clincove’s software and hardware to manage the end-to-end clinical trial workflow from planning & preparing, through participant enrollment & data collection to data analysis & reporting.
                    </span>
                    <div>
                        <button  onClick={() => window.location.href = "mailto:info@clincove.com"} aria-label="Get Started" style={{ backgroundColor: "black", marginRight: "20px" }} class="p-button p-component text-xl border-none mt-3 bg-black-300 font-normal line-height-3 px-3 text-white" type="button" data-pc-name="button" data-pc-section="root">
                            <span class="p-button-label p-c" data-pc-section="label">
                                Book a Demo
                            </span>
                        </button>
                        <button onClick={()=> nav('/contact-us')} aria-label="Get Started" style={{ backgroundColor: "white", color: "black", border: "1px solid black !important", borderColor: "black" }} class="p-button p-component text-xl mt-3 bg-black-300 font-normal line-height-3 px-3 text-black" type="button" data-pc-name="button" data-pc-section="root">
                            <span class="p-button-label p-c" data-pc-section="label">
                                Streamline Your Trial {' '}
                                <i className='pi pi-arrow-right'></i>
                            </span>
                        </button>
                    </div>
                </div>
              
              
            </div>
        </div>
    )
}