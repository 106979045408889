import { useParams } from "react-router-dom"
import { solutions } from "../constants"
import { useNavigate } from 'react-router-dom'
import temp from "./images/Background5.png";
import { Hero } from "./hero";
import React, { useEffect, useRef, useState } from 'react';
import './fade.css'
import "primeicons/primeicons.css";
export const Solution_Info = () => {
  var solution_name = useParams().solution_name;
  const [solution, setsolution] = useState({})
  const nav = useNavigate();
 
  useEffect(() => {
    const foundSolution = solutions.find(p => p.name.toLowerCase() === solution_name.toLowerCase());

    if (foundSolution) {
      setsolution(foundSolution);
    } else {
      // Redirect to /404 if no solution is found
      nav('/404');
    }
  }, [solution_name, nav]);

  const contactUs = () => {
    return (
      <>
        <button onClick={() => nav('/contact-us')} style={{ borderRadius: "52px", backgroundColor: "#03DAC6", color: "black", paddingBottom: "10px" }} aria-label="Get Started" class="black" className="p-button p-component text-3xl border-none mt-4 font-normal line-height-3 px-3" type="button" data-pc-name="button" data-pc-section="root">
          <span class="p-button-label p-c text-900" data-pc-section="label">
            Contact Us{' '}
            <i className='pi pi-arrow-right'></i>
          </span>
        </button>
      </>
    )
  }
  return (
    <>

      <Hero />

      <div id="hero" className="py-2 px-4 lg:px-1 2xl:px-8 my-0 lg:mx-0 pb-0" style={{ backgroundColor: "#F6F9FC" }}>
        <div className="grid mt-0 lg:px-1 2xl:px-8 pb-0 lg:pb-4 lg:px-1 2xl:px-8 md:pb-0">
          <div className="col-12 lg:col-12 flex flex-column lg:align-items-center text-left lg:text-center lg:mt-6 mt-4" >
            <div className="lg:w-8 w-12">
              <h2 className="line-height-1 text-900 text-5xl font-normal" style={{ marginTop: "0px" }}>{solution.mission}</h2>
            </div>

          </div>
          <div className="col-12 lg:col-12 flex flex-column lg:align-items-center text-left lg:text-center mt-4 mb-4">
            <img src={solution.banner} className="lg:w-8 w-12" alt="mockup" />
          </div>




        </div>
      </div>
      <div id="hero" className="w-12 px-4" style={{ backgroundColor: "#3700B3" }}>
        <div className="grid mb-0 lg:py-4 py-2 pt-0 lg:px-8 lg:pb-4" style={{ backgroundColor: "#3700B3", color: "white" }}>
          <div className="col-12 lg:col-12 my-auto flex flex-column lg:align-items-center text-left lg:text-center" >

            <div className="lg:w-8 w-12 text-3xl line-height-3 mr-0 md:mr-2 text-left lg:text-center white" style={{ width: "100%", fontColor: "white" }} >
              {solution.summary}

            </div>
          </div>
        </div>
      </div>

      <div id="hero" className="lg:py-2 py-4 px-0 pb-4 lg:px-1 2xl:px-8 px-4 my-0 lg:mx-0" style={{ backgroundColor: "#F6F9FC" }}>
        <div className="grid mt-0 2xl:px-8 lg:px-2 pb-0 lg:pb-4 md:pb-0">

          <div className="flex justify-content-center col-12 lg:col-6  flex-order-1 lg:flex-order-0 lg:mt-6 mt-2" >
            <div className="grid flex flex-column lg:align-items-center">
              <div className="col-12 text-center">
                <img src={solution.img1} className="lg:w-10 w-12" alt="mockup" />
              </div>
              <div className="col-12 lg:col-10 mt-0">
                <span className="text-700 text-xl line-height-3 mr-0 md:mr-2">
                  {solution.text1}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-6 lg:mt-6 mt-0 flex flex-column lg:align-items-end text-left lg:text-left">
            <div className="grid flex flex-column lg:align-items-center">

              <div className="col-12 text-center">
                <span>
                  <img src={solution.img2} className="lg:w-10 w-12" alt="mockup" />
                </span>
              </div>

              <div className="col-12 lg:col-10 mt-0">
                <span className="text-700 text-xl line-height-3 mr-0 md:mr-2">
                  {solution.text2}
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
      {solution.img3 &&
        <>

          <div id="hero" className="lg:py-2 py-4 px-0 pb-4 lg:px-1 2xl:px-8 px-4 my-0 lg:mx-0" style={{ backgroundColor: "#3700B3" }}>
            <div className="grid pt-4 pb-0 lg:px-8 pb-4 pt-4 lg:pt-4" style={{ backgroundColor: "#3700B3", color: "white", fontWeight: "bold" }}>
              <div className="col-12 lg:col-12 flex flex-column lg:align-items-center text-left lg:text-left" >

                <div className="lg:w-8 w-12 text-3xl line-height-3 mr-0 md:mr-2 text-left lg:text-center" style={{ width: "100%" }} >
                  {solution.text4}
                </div>


              </div>

            </div>
          </div>
          <div id="hero" className="lg:py-2 py-4 px-0 pb-4 lg:px-1 2xl:px-8 px-4 my-0 lg:mx-0" style={{ backgroundColor: "#F6F9FC" }}>

            <div className="grid mt-0 pb-0 lg:px-8 md:pb-0">

              <div className="col-12 lg:col-12 flex flex-column lg:align-items-center text-left lg:text-center mt-4">
                <img src={solution.img3} className="lg:w-8 w-12" alt="mockup" />
              </div>

              <div className="col-12 lg:col-12 flex flex-column lg:align-items-center text-left lg:mt-6 mt-4">
                <div className="lg:w-8 w-12 text-700 text-xl line-height-3 mr-0 md:mr-2 text-left lg:text-center" style={{ width: "100%" }} >{solution.text3}</div>
              </div>


            </div>
          </div>



        </>
      }




      {!solution.img3 &&
        <>
          <div id="hero" className="w-12 px-4" style={{ backgroundColor: "#3700B3" }}>
            <div className="grid mb-0 lg:pb-4 lg:pt-4 py-4 lg:px-8 " style={{ backgroundColor: "#3700B3", color: "white" }}>
              <div className="col-12 lg:col-12 my-auto flex flex-column lg:align-items-center text-left lg:text-center" >

                <div className="lg:w-8 w-12 text-3xl line-height-3 mr-0 md:mr-2 text-left lg:text-center white" style={{ width: "100%", fontColor: "white" }} >
                  {solution.text3}

                </div>
              </div>
              <div className="col-12 lg:col-12 my-auto flex flex-column lg:align-items-center text-left lg:text-center" >

                <div className="lg:w-8 w-12  text-3xl line-height-3 mr-0 md:mr-2 text-left lg:text-center" style={{ width: "100%" }} >
                  {solution.text4}
                </div>
              </div>

              <div className="col-12 lg:col-12 my-auto flex flex-column lg:align-items-center text-left lg:text-center" >

                <div className="lg:w-8 w-12 text-3xl line-height-3 mr-0 md:mr-2 text-left lg:text-center" style={{ width: "100%" }} >
                  {solution.text5}
                </div>


              </div>




            </div>

          </div>
        </>
      }
      <div id="hero" className="w-12 px-4" style={{ backgroundColor: "#F6F9FC" }}>
        <div className="grid mt-0 lg:mt-0 mb-4 pb-0 lg:px-8 md:pb-8" >
          <div className="col-12 lg:col-12 my-auto flex flex-column lg:align-items-center text-left lg:text-center" >
            <div className="lg:w-8 w-12 text-700 text-3xl line-height-3 mr-0 md:mr-2 text-left lg:text-center" style={{ width: "100%" }} >
              {contactUs()}
            </div>
          </div>
        </div>
      </div>
    </>
  )


}