import { useNavigate } from 'react-router-dom'
import unified_platform from '../sections/images/unified-platform.png'
export const Unified_Platform = () => {
    const nav = useNavigate();
    return (<>
        
        <div className="py-4 px-0 2xl:px-8 mx-0 my-0 lg:mx-0 " style={{ backgroundColor: "#F6F9FC", clipPath: "polygon(0 0, 100% 0, 100% 90%, 0% 100%, 0 100%)", borderTopColor: "white", borderTop: "1px solid white" }}>

            <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 lg:mx-0" style={{ zIndex: 2 }}>
                <div className="grid mt-0 lg:px-8 pb-2 md:pb-8" >
                    <div className="col-12 lg:col-7 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                      
                        <h2 className="line-height-1 text-700 font-normal purple mb-0" class="purple">Unified platform</h2>
                        <h2 className="line-height-1 text-900 text-4xl font-normal mt-0">A fully integrated suite of clinical trial products</h2>
                    </div>

                    <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start mt-5">
                        <span className="text-700 text-xl line-height-3 mr-0 md:mr-2">
                            Our integrated suite revolutionizes clinical trial management. The eCTMRS provides real-time access to critical trial data, streamlining enrollment, adherence, and data capture. Modules include EDC, eSource, eConsent, ePRO/eCOA, eISF/eTMF and Budget Assistance.
                        </span>
                    </div>
                    <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start">

                        <span className="text-700 text-xl line-height-3 mr-0 md:mr-2">
                            It simplifies processes, enhances data quality, and drives impactful discoveries for improved patient outcomes. Researchers experience streamlined processes, enhanced data quality, and simplified compliance, bringing us closer to impactful discoveries and improved patient outcomes.
                        </span>

                    </div>
                    <div className="col-12 lg:col-12 my-auto flex flex-column text-left lg:text-left lg:align-items-start pt-5"></div>
                    <div className="col-12 lg:col-6 my-auto flex flex-column text-left lg:text-left lg:align-items-start mt-0">
                        <div>

                            <button  onClick={() => nav('/contact-us')} aria-label="Get Started" class="p-button p-component text-xl border-none font-normal line-height-3 px-3 text-white" type="button" data-pc-name="button" data-pc-section="root" style={{ backgroundColor: "#6200EE", borderRadius: "52px" }} >
                                <span class="p-button-label p-c" data-pc-section="label">
                                    Start with eCMTRS {' '}
                                    <i className='pi pi-arrow-right'></i>
                                </span>


                            </button>

                        </div>

                    </div>


                </div>

                <div>
                    <img src={unified_platform} className="w-12" alt="mockup" />
                </div>



            </div>
          
        </div >
    </>
    )
}