import background1 from '../sections/images/Background1.png'
//import certificate from '../sections/images/security.jpg';
import certificate from '../sections/images/certificate (1) (1).jpg';
import 'boxicons/css/boxicons.min.css';
import Icon from '@mdi/react';
import { mdiPill, mdiScaleBalance } from '@mdi/js';
import { useNavigate } from 'react-router-dom';


export const Security_Privacy = () => {
    const nav = useNavigate()
    return (
        <>
            <div className="py-0 px-0 2xl:px-8 mx-0 my-0 lg:mx-0" style={{ backgroundColor: "#3700B3", border: "0px solid" }}>

                <div id="highlights" className="py-1 px-4 xl:px-8 mx-0 my-0 lg:mx-0" >
                    <div className="grid mt-0 lg:px-8 pb-2 md:pb-2" >
                        <div className="col-12 lg:col-6 my-auto flex flex-column text-left lg:text-left lg:align-items-start mt-4 pr-3" >

                            <h2 className="line-height-1 text-700 font-normal green mb-0" class="green">Designed for security and privacy</h2>
                            <h2 className="line-height-1 text-900 text-4xl white mt-0" class="white" style={{marginTop: "0px"}}>Protecting participant data and ensuring compliance</h2>
                            <span class="white" className=" text-xl line-height-3 mr-0 md:mr-2 mt-4 white" >
                                Designed with a strong focus on protecting sensitive trial data, our suite employs robust security measures to ensure compliance with privacy regulations. By implementing stringent access controls, encryption protocols, and data anonymization techniques, clinical sites can confidently manage and protect participant information within a secure environment.
                            </span>
                            <button onClick={()=> nav('/blog')} style={{ borderRadius: "52px", backgroundColor: "#03DAC6", color: "black", marginBottom: "10px" }} aria-label="Get Started" class="black" className="p-button p-component text-xl border-none mt-8 font-normal line-height-3 px-3" type="button" data-pc-name="button" data-pc-section="root">
                                <span class="p-button-label p-c" data-pc-section="label">
                                    Read more{' '}
                                    <i className='pi pi-arrow-right'></i>
                                </span>
                            </button>
                        </div>

                        <div className="flex justify-content-center flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0 mt-5" style={{ borderRadius: '8px' }}>
                            <div style={{ textAlign: "center"}}>
                                <img src={certificate} className="w-12" style={{height: "auto"}} alt="mockup" />
                            </div>
                         
                        </div>
                    </div>
                    <div className="grid justify-content-left mt-0 lg:px-8">

                        <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-1 mt-0 lg:mt-0">
                            <div
                                style={{
                                    height: '250px',
                                    paddingTop: "14px",
                                    paddingRight: "14px",
                                    paddingBottom: "14px",
                                    paddingLeft: "0px",
                                    borderRadius: '10px',
                                    backgroundColor: "#3700B3"
                                }}
                            >
                                <div className="p-3 h-full" style={{
                                    borderRadius: '8px',
                                   
                                }}>
                                    < div className="flex align-items-center justify-content-center mb-0" style={{ backgroundColor: "white", width: '3.5rem', height: '3.5rem', borderRadius: '10px' }}>
                                        <Icon size={1.5} color={'black'} path={mdiScaleBalance} />
                                    </div>
                                    <h5 className="mb-2 text-900" class="white">Reduce your compliance burden</h5>
                                    <span className="text-600" class="white" >With advanced encryption, secure data storage, and automated privacy controls, sites gain efficiency, ensuring compliance while protecting sensitive information.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-1 mt-0 lg:mt-0">
                            <div
                                 style={{
                                    height: '250px',
                                    paddingTop: "14px",
                                    paddingRight: "14px",
                                    paddingBottom: "14px",
                                    paddingLeft: "0px",
                                    borderRadius: '10px',
                                    backgroundColor: "#3700B3"
                                }}
                            >
                                <div className="p-3 h-full" style={{ borderRadius: '8px' }}>
                                    <div className="flex align-items-center justify-content-center mb-0" style={{ backgroundColor: "white", width: '3.5rem', height: '3.5rem', borderRadius: '10px' }}>
                                        <Icon size={1.5} color={'black'} path={mdiPill} />
                                    </div>
                                    <h5 className="mb-2 text-900" class="white" >Attract more sponsors</h5>
                                    <span className="text-600" class="white" >Enhance your appeal and attract more sponsors by leveraging advanced technological solutions to remain competitive in today's digital landscape.</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-1 mt-0 lg:mt-0">
                            <div
                                style={{
                                    height: '250px',
                                    paddingTop: "14px",
                                    paddingRight: "14px",
                                    paddingBottom: "14px",
                                    paddingLeft: "0px",
                                    borderRadius: '10px',
                                    backgroundColor: "#3700B3"
                                }}
                            >
                                <div className="p-3 h-full" style={{ borderRadius: '8px' }}>
                                    <div className="flex align-items-center justify-content-center mb-0" style={{ backgroundColor: "white", width: '3.5rem', height: '3.5rem', borderRadius: '10px' }}>
                                        <i className="bx bx-check-shield" style={{ fontSize: "2.5rem", color: "black" }} ></i>

                                    </div>
                                    <h5 className="mb-2 text-900" class="white">Increase participant trust</h5>
                                    <span className="text-600" class="white" >Strengthen trust by providing robust security, streamlined operations, and compliance, instilling confidence in all participants across the clinical spectrum.</span>
                                </div>
                            </div>
                        </div>


                    </div>





                </div >

            </div >

        </>
    )
}