import background1 from '../sections/images/Background1.png'
import 'boxicons/css/boxicons.min.css';
import Icon from '@mdi/react';
import { mdiPill, mdiScaleBalance } from '@mdi/js';


export const About_Us = () => {
    return (
        <>

            <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8" style={{ backgroundColor: "#6200EE", border: "0px solid" }}>


                <div className="grid mt-0 lg:px-8 pb-2 md:pb-8 lg:px-8" >
                    <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start" >
                        <h2 class="green">
                            About Us
                        </h2>
                        <div className="lg:mt-4 mt-4">
                            <span className='white text-xl'>   CLINCOVE is a leading provider of innovative solutions in the healthcare industry, specializing in a comprehensive clinical trial medical records system that revolutionizes the way medical data is collected, managed, and utilized. We empower researchers, healthcare professionals, and patients to drive advancements in medical research and improve patient outcomes.
                            </span>
                        </div>
                        <div className="lg:mt-4 mt-4" style={{}}>
                            <span className='white text-xl'>  At CLINCOVE, we understand the critical importance of data privacy and security. That's why we have implemented rigorous safeguards and compliance measures to protect sensitive patient information throughout the entire lifecycle of a clinical trial. With our advanced encryption protocols and strict data governance practices, we provide peace of mind to all stakeholders involved in the research process.
                            </span>
                        </div>
                        <div className="lg:mt-4 mt-4" style={{}}>
                            <span className='white text-xl'> We pride ourselves on our commitment to exceptional customer service and support. Our team of experienced professionals works closely with clients to tailor our clinical trial medical records system to their specific needs, ensuring seamless integration, efficient training, and ongoing assistance. We strive to build long-lasting partnerships based on trust, reliability, and a shared dedication to advancing medical science.   </span>
                        </div>
                    </div>













                </div>

            </div >

        </>
    )
}