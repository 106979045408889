import background1 from '../sections/images/Background1.png'
import 'boxicons/css/boxicons.min.css';
import Icon from '@mdi/react';
import { mdiPill, mdiScaleBalance } from '@mdi/js';


export const Job = () => {
    return (
        <>
            <div className="py-4 px-4 lg:px-8 mx-0 my-0 lg:mx-0" style={{ backgroundColor: "#6200EE", border: "0px solid", paddingBottom: "80px" }}>

                <div id="highlights" className="py-0 px-4 lg:px-8 mx-0 my-0 lg:mx-0" >
                    <div className="grid mt-0 pb-2 md:pb-2" >
                        <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start" >
                            <h2 class="green">
                                Jobs
                            </h2>
                            <h2 className="line-height-1 text-200 text-xl pb-4" class="white">
                            <span className='white text-xl'>At CLINCOVE we are always looking for new and dynamic team members that share our vision of advancing healthcare for researchers, patients and beyond.
                            </span>
                            </h2>
                            <button  onClick={() => window.location.href = "mailto:info@clincove.com"} style={{ borderRadius: "52px", backgroundColor: "#03DAC6", color: "black", marginBottom: "10px" }} aria-label="Get Started" class="black" className="p-button p-component text-xl border-none mt-3 font-normal line-height-3 px-3" type="button" data-pc-name="button" data-pc-section="root">
                                <span class="p-button-label p-c" data-pc-section="label">
                                    Submit Your CV{' '}
                                    <i className='pi pi-arrow-right'></i>
                                </span>
                            </button>
                        </div>


                    </div>










                </div>

            </div >

        </>
    )
}