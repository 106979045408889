import unified_platform from '../sections/images/unified-platform.png'
export const Privacy = () => {
    return (<>

        <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8" style={{ backgroundColor: "#F6F9FC" }}>


            <div className="grid mt-0 lg:px-8 pb-2 md:pb-8" >
                <div className="col-12 lg:col-12 my-auto flex flex-column text-left lg:text-left lg:align-items-start">

                    <h2 className="line-height-1 text-900 text-4xl font-normal">  Privacy Policy</h2>
                </div>

                <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2">

                        <h3 class="line-height-1 text-900 font-normal">1. Introduction</h3>
                        Welcome to <a href="http://www.clincove.com/" target="_blank" rel="noopener noreferrer">www.clincove.com</a>. We are committed to protecting your privacy. This Privacy Policy outlines the types of information we collect from you while you use our website, how we use that information, and the steps we take to safeguard it.

                        <h3 class="line-height-1 text-900 font-normal">2. Information Collection and Use</h3>
                        We collect various types of information for several purposes to provide and improve our service to you.

                        <h5 class="line-height-1 text-900 font-normal">Types of Data Collected:</h5>
                        <ul>
                            <li>Personal Data: While using our Website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. This may include, but is not limited to, your email address, name, phone number, and postal address.</li>
                            <li>Usage Data: We may also collect information on how the Website is accessed and used. This Usage Data may include information such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, our Website pages that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.</li>
                        </ul>

                        <p><strong>Use of Data:</strong> <a href="http://www.clincove.com/" target="_blank" rel="noopener noreferrer">www.clincove.com</a> uses the collected data for various purposes:</p>
                        <ul>
                            <li>To provide and maintain our Website</li>
                            <li>To notify you about changes to our Website</li>
                            <li>To allow you to participate in interactive features of our Website when you choose to do so</li>
                            <li>To provide customer support</li>
                            <li>To gather analysis or valuable information so that we can improve our Website</li>
                            <li>To monitor the usage of our Website</li>
                            <li>To detect, prevent, and address technical issues</li>
                        </ul>

                        <h3 class="line-height-1 text-900 font-normal">3. Sharing Your Data</h3>
                        <p>We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>

                        <h3 class="line-height-1 text-900 font-normal">4. Sharing Your Data</h3>
                        The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.

                        <h3 class="line-height-1 text-900 font-normal">5. Cookies and Tracking Technologies</h3>
                        We use cookies and similar tracking technologies to track the activity on our Website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Website. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Website.

                        <h3 class="line-height-1 text-900 font-normal">6. Links to Other Sites</h3>
                        Our Website may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.


                        <h3 class="line-height-1 text-900 font-normal">7. Children's Privacy</h3>
                        <p>Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us immediately. We will take steps to remove that information from our servers if we become aware that we have collected Personal Data from children without verification of parental consent.</p>

                        <h3 class="line-height-1 text-900 font-normal">8. Changes to This Privacy Policy</h3>
                        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Website, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

                        <h3 class="line-height-1 text-900 font-normal">9. Your Consent</h3>
                        <p>By using our Website, you hereby consent to our Privacy Policy and agree to its terms.</p>

                        <h3 class="line-height-1 text-900 font-normal">10. Contact Us</h3>
                        <p>If you have any questions about this Privacy Policy, please contact us:</p>
                        <ul>
                            <li>By email: info@clincove.com</li>
                        </ul>

                        <h3 class="line-height-1 text-900 font-normal">Effective Date</h3>
                        <p>This policy is effective as of March 1, 2024.</p>


                    </span>
                </div>





            </div>




        </div>


    </>
    )
}