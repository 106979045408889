import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../index.css';
import ReactDOM from 'react-dom';

import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image'
import { ListBox } from 'primereact/listbox'
import { Menubar } from 'primereact/menubar'

import { products } from './constants';
import logo from './sections/images/clincove.svg'
import test from './sections/images/clincove copy.svg'
import { useNavigate } from 'react-router-dom';



export const Header = () => {


    const navigateTo = useNavigate();

    const items = [
        {
            label: 'Products',
            className: "menu-item-center",
            items: [
                {

                    icon: <>

                        <span>
                            <div className="flex align-items-center">

                                <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Pilot</label>
                                <label>(eCTMRS)</label>

                            </div>
                            <label style={{ fontSize: "1.25rem" }}>Upload and facilitate the storage, organization, and retrieval of...</label>
                        </span>
                    </>,
                    command: () => { navigateTo("/products/pilot"); window.location.reload(); }

                },
                {
                    icon: (
                        <>
                            <span>
                                <div className="flex align-items-center">

                                    <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Vista</label>
                                    <label>(eSource)</label>
                                </div>
                                <label style={{ fontSize: "1.25rem" }}>Custom built eSource forms that replace traditional paper-based...</label>
                            </span>
                        </>
                    ),
                    command: () => { navigateTo("/products/vista"); window.location.reload(); }
                },
                {
                    icon: (
                        <>
                            <span>
                                <div className="flex align-items-center">
                                    <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Echo</label>
                                    <label>(ePRO & eCOA)</label>
                                </div>
                                <label style={{ fontSize: "1.25rem" }}>Custom built ePRO and eCOA solutions to collect patient-reported...</label>
                            </span>
                        </>
                    ), command: () => { navigateTo("/products/echo"); window.location.reload(); }
                },
                {
                    icon: (
                        <>
                            <span>
                                <div className="flex align-items-center">
                                    <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Beacon</label>
                                    <label>(eTMF)</label>
                                </div>
                                <label style={{ fontSize: "1.25rem" }}>Store all your regulatory documents in a comprehensive repository, custom...</label>
                            </span>
                        </>
                    ),
                    command: () => { navigateTo("/products/beacon"); window.location.reload(); }
                },
                {
                    icon: (
                        <>
                            <span>
                                <div className="flex align-items-center">
                                    <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Zenith</label>
                                    <label>(EDC)</label>
                                </div>
                                <label style={{ fontSize: "1.25rem" }}>An AI powered EDC system that integrates data from records within...</label>
                            </span>
                        </>
                    ),
                    command: () => { navigateTo("/products/zenith"); window.location.reload(); }
                },
                {
                    icon: (
                        <>
                            <span>
                                <div className="flex align-items-center">
                                    <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Journey</label>
                                    <label>(Budget Assistant)</label>
                                </div>
                                <label style={{ fontSize: "1.25rem" }}>A popup that displays information related to an element when the...</label>
                            </span>
                        </>
                    ),
                    command: () => { navigateTo("/products/journey"); window.location.reload(); }
                },
            ]
        },
        {
            label: 'Solutions', 
            className: "menu-item-center",
            items: [
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Sites</label> <br /><label style={{ fontSize: "1.25rem" }}>Manage the end-to-end clinical trial process with ease</label>
                        </span>,
                    command: () => { navigateTo("/solutions/sites"); window.location.reload(); }
                },
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Sponsors</label> <br /><label style={{ fontSize: "1.25rem" }}>Manage the end-to-end clinical trial process with ease</label>
                        </span>,
                    command: () => { navigateTo("/solutions/sponsors"); window.location.reload(); }
                },
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>CROs</label> <br /><label style={{ fontSize: "1.25rem" }}>Manage the end-to-end clinical trial process with ease</label>
                        </span>,
                    command: () => { navigateTo("/solutions/cros"); window.location.reload(); }
                },
            ]
        },
        { label: 'Resources', className: "menu-item-center", command: () => { navigateTo("/resources"); window.location.reload(); } },
        {
            label: 'Company', className: "menu-item-center", items: [
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>About Us</label>
                        </span>
                    , command: () => { navigateTo("/about-us"); window.location.reload(); }
                },
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Jobs</label>
                        </span>
                    , command: () => { navigateTo("/jobs"); window.location.reload(); }
                },
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Newsroom</label>
                        </span>
                    , command: () => { navigateTo("/newsroom"); window.location.reload(); }
                },
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Legal</label>
                        </span>
                    , command: () => { navigateTo("/legal"); window.location.reload(); }
                },
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Privacy Policy</label>
                        </span>
                    , command: () => { navigateTo("/privacy-policy"); window.location.reload(); }
                },
                {
                    icon:
                        <span> <label style={{ paddingRight: "3px", fontWeight: "bold" }}>Contact Us</label>
                        </span>
                    , command: () => { navigateTo("/contact-us"); window.location.reload(); }
                },
            ]
        },
        // ... other menu items
    ];

    const endItems = (
        <div style={{ width: "250px" }}>


            <button onClick={() => navigateTo('/contact-us')} class="p-button p-component text-xl bg-black-300 font-normal line-height-3 text-black" aria-label="Get Started" style={{ width: "50%", backgroundColor: "white", color: "black", border: "1px solid black !important", borderColor: "black", marginRight: "5%", fontWeight: "600 !important", padding: "5px 5px" }} type="button" data-pc-name="button" data-pc-section="root">
                <span class="p-button-label p-c" data-pc-section="label" style={{ fontWeight: "600 !important" }}>
                    Book a Demo
                </span>
            </button>
            <button onClick={() => window.location.href = "https://admin.us.clincove.com/auth/login"} aria-label="Get Started" style={{ width: "45%", backgroundColor: "black", padding: "5px 5px", border: "1px solid black !important", borderColor: "black", }} class="p-button p-component text-xl border-none bg-black-300 font-normal line-height-3 text-white" type="button" data-pc-name="button" data-pc-section="root">
                <span class="p-button-label p-c" data-pc-section="label">
                    Login
                </span>
            </button>
        </div>
    );

    const [sidebar, setSidebar] = useState(false);

    const customHeader = (
        <React.Fragment>
            <img src={test} height="30px" onClick={() => { navigateTo('/'); window.location.reload(); }} className="mr-2 lg:mr-2" />
        </React.Fragment>
    );

    const endItemObjects = [
        {
            // Define template for rendering your custom JSX
            template: () => (
                <button onClick={() => navigateTo('/contact-us')} /* other props */>
                    Book a Demo
                </button>
            ),
        },
        {
            template: () => (
                <button onClick={() => window.location.href = "https://admin.us.clincove.com/auth/login"} /* other props */>
                    Login
                </button>
            ),
        },
    ];


    const buttonItems = [
        { label: 'Book a Demo', command: () => { navigateTo('/contact-us'); window.location.reload(); } },
        { label: 'Login', command: () => { window.location.href = "https://admin.us.clincove.com/auth/login" } },

    ];

    const combinedItems = [...items, ...endItemObjects];
    return (<>
        {/*}

        <div className="card" style={{ paddingTop: "20px" }}>
            <div className="formgrid grid">
                <div class="field col-12 lg:col-1"></div>
                <div class="field col-12 lg:col-3">
                    <img alt="logo" class="logo_header hoverable" onClick={() => navigateTo('/')} src={logo} />
                    <div className="mobile" onClick={() => setSidebar(!sidebar)}>
                        <i className="pi pi-bars" style={{ fontSize: "30px", paddingRight: "10px" }}></i>
                    </div>

                </div>

                <div class="desktop field col-12 lg:col-8">



                    <Menubar model={items} end={endItems} style={{ marginTop: "3px", paddingTop: "0px", backgroundColor: 'white', border: "none", fontSize: "1.25rem" }} />
                </div>



            </div>


        </div>
    */}
        <div className="card" style={{ paddingTop: "20px" }}>
            <div className="grid lg:ml-4">
              
                <div className="col-12 lg:col-3 justify-content-center">
                    {/* Logo */}
                    <img alt="logo" className="logo_header hoverable lg:w-12" onClick={() => navigateTo('/')} src={logo} />
                    <div className="mobile" onClick={() => setSidebar(!sidebar)}>
                        <i className="pi pi-bars" style={{ fontSize: "30px", paddingRight: "10px" }}></i>
                    </div>

                </div>

                <div className="col-12 lg:col-6 desktop 2xl:pl-4 pt-1">
                    {/* Menubar for the first four items */}
                    <Menubar model={items} style={{ backgroundColor: 'white', border: "none", fontSize: "1.25rem" }} />
                </div>

                <div className="col-12 lg:col-3 center-and-hide">
                    {/* Custom buttons */}
                    <button onClick={() => navigateTo('/contact-us')} className="p-button p-component text-xl bg-black-300 font-normal line-height-3 text-black" style={{ backgroundColor: "white", color: "black", border: "1px solid black", marginRight: "5%", fontWeight: "600", padding: "5px 15px", whiteSpace: "nowrap" }}>
                        Book a Demo
                    </button>
                    <button onClick={() => window.location.href = "https://admin.us.clincove.com/auth/login"} className="p-button p-component text-xl border-none bg-black-300 font-normal line-height-3 text-white" style={{ backgroundColor: "black", padding: "5px 15px", border: "1px solid black", whiteSpace: "nowrap" }}>
                        Login
                    </button>
                </div>
                <div className="col-12 lg:col-3 tablet">
                    {/* Custom buttons */}
                    <button onClick={() => navigateTo('/contact-us')} className="p-button p-component text-xl bg-black-300 font-normal line-height-3 text-black" style={{ backgroundColor: "white", color: "black", border: "1px solid black", marginRight: "5%", fontWeight: "600", padding: "5px 10px", whiteSpace: "nowrap" }}>
                        Book a Demo
                    </button>
                    <button onClick={() => window.location.href = "https://admin.us.clincove.com/auth/login"} className="p-button p-component text-xl border-none bg-black-300 font-normal line-height-3 text-white" style={{ backgroundColor: "black", padding: "5px 10px", border: "1px solid black", whiteSpace: "nowrap" }}>
                        Login
                    </button>
                </div>
            </div>
        </div>

        <Sidebar visible={sidebar} header={customHeader} position="right" onHide={() => setSidebar(false)}>
            <SidebarLayout />
        </Sidebar>


    </>)

}

const SidebarLayout = () => {
    const navigate = useNavigate();

    const solutions = [
        { name: 'Sites', color: 'url(#348552010177)' },
        { name: 'Sponsors', color: 'BR' },
        { name: 'CROs', color: 'CN' },
    ];

    const company = [
        { name: 'About Us', color: 'BR', url: 'about-us' },
        { name: 'Jobs', color: 'BR', url: 'jobs' },
        { name: 'Newsroom', color: 'BR', url: 'newsroom' },
        { name: 'Legal', color: 'BR', url: 'legal' },
        { name: 'Privacy Policy', color: 'BR', url: 'privacy-policy' },
        { name: 'Contact Us', color: 'BR', url: 'contact-us' },
    ];

    const resources = [{ name: 'Resources' }]
    const productsTemplate = (option) => {

        return (
            <div className="flex align-items-center">

                <span>
                    <label style={{ paddingRight: "3px", fontWeight: "bold" }}>{option.name}</label>
                    <label>({option.sub})</label>
                </span>
            </div>
        );
    };

    const solutionsTemplate = (option) => {

        return (
            <div className="flex align-items-center">
                <div onClick={() => { navigate("/" + option.name.toLowerCase()); window.location.reload() }}>
                    <label style={{ paddingRight: "3px", fontWeight: "500" }}>{option.name}</label>

                </div>
            </div>
        );
    };
    const companyTemplate = (option) => {

        return (
            <div className="flex align-items-center">
                <div onClick={() => { navigate("/" + option.url); window.location.reload() }}>
                    <label style={{ paddingRight: "3px", fontWeight: "500" }}>{option.name}</label>

                </div>
            </div>
        );
    };


    const navigateTo = (v, type) => {
        if (type == 'products') {
            navigate("/products/" + v.name.toLowerCase());
            console.log(v.name, v.name.toLowerCase())
            window.location.reload();
        } else if (type == 'solutions') {
            navigate("/solutions/" + v.name.toLowerCase());
            console.log(v.name, v.name.toLowerCase())
            window.location.reload();
        }
    }
    return (
        <>
            <div><hr /></div>
            <h5 style={{ margin: "0px" }}>PRODUCTS</h5>
            <div className="card flex" style={{ margin: "0px" }}>
                <ListBox onChange={(e) => navigateTo(e.value, 'products')} options={products} optionLabel="name"
                    itemTemplate={productsTemplate} className="w-full md:w-14rem" style={{ border: "none" }} />

            </div>
            <div><hr /></div>

            <h5 style={{ margin: "0px" }}>SOLUTIONS</h5>
            <div className="card flex " style={{ margin: "0px" }}>
                <ListBox onChange={(e) => navigateTo(e.value, 'solutions')} options={solutions} optionLabel="name"
                    itemTemplate={solutionsTemplate} className="w-full md:w-14rem" style={{ border: "none" }} />
            </div>
            <div><hr /></div>


            <div className="card flex" style={{ margin: "0px" }}>
                <ListBox onChange={(e) => navigateTo(e.value)} options={resources} optionLabel="name"
                    itemTemplate={solutionsTemplate} className="w-full md:w-14rem" style={{ border: "none" }} />
            </div>

            <div><hr /></div>
            <h5 style={{ margin: "0px" }}>COMPANY</h5>
            <div className="card flex " style={{ margin: "0px" }}>
                <ListBox onChange={(e) => navigateTo(e.value)} options={company} optionLabel="name"
                    itemTemplate={companyTemplate} className="w-full md:w-14rem" style={{ border: "none" }} />
            </div>
            <div className="bg-white-100" style={{ backgroundColor: "white" }}>
                <div className="bg-gray-100 flex justify-content-center align-items-center" onClick={() => window.location.href = "mailto:info@clincove.com"} style={{}}>
                    <h5>Book a Demo</h5>
                </div>
                <div className="bg-gray-100 flex justify-content-center align-items-center mt-2" onClick={() => window.location.href = "https://admin.us.clincove.com/auth/login"} style={{}}>
                    <h5>Login</h5>
                </div>
            </div>

        </>
    )
}