import { useNavigate } from "react-router-dom"

export const Newsroom = () => {
    const nav = useNavigate();
    return (
        <>

            <div className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8" style={{ backgroundColor: "#6200EE", borderTopColor: "white", borderTop: "1px solid white" }}>
                <div id="highlights" className="mt-0 lg:px-8 pb-2 md:pb-8 lg:px-8">
                    <div className="mt-0 pb-0 lg:pb-0" >
                        <div className="col-12 lg:col-10 my-auto flex flex-column text-left lg:text-left lg:align-items-start" >
                            <h2 class="green">
                                Newsroom
                            </h2>
                            <h2 className="line-height-1 text-900 text-4xl" class="white"  >Check back for exciting news and updates.</h2>
                            
                            <button  onClick={() => nav('/contact-us')} style={{ borderRadius: "52px", backgroundColor: "#03DAC6", color: "black", marginBottom: "10px" }} aria-label="Get Started" class="black" className="p-button p-component text-xl border-none mt-5 font-normal line-height-3 px-3" type="button" data-pc-name="button" data-pc-section="root">
                                <span class="p-button-label p-c" data-pc-section="label">
                                    Contact Us{' '}
                                    <i className='pi pi-arrow-right'></i>
                                </span>
                            </button>
                        </div>

                       
                    </div>
                   


                  





                </div>
            </div>
        </>
    )

}