import React, { useContext, useRef, useState } from 'react';


import background2 from '../sections/images/Background2.png'
import background3 from '../sections/images/Background4.png'

import hero from "../sections/images/hero.png"
import { StyleClass } from 'primereact/styleclass';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Hero } from '../sections/hero';
import { Unified_Platform } from '../sections/unified-platform';
import { Security_Privacy } from '../sections/security-privacy';
import { Why_Clincove } from '../sections/why-clincove';
import { HeroNoText } from '../sections/hero-no-text';
import { Guides } from '../sections/guides';
import { WhitePaper } from '../sections/white-paper';



export const Blog = () => {

    return (<>

        <HeroNoText />

        <div id="hero" className="py-2 px-4 lg:px-8 mx-0 my-0 lg:mx-0" style={{ backgroundColor: "#F6F9FC" }}>
            <div className="grid mt-0 lg:px-8 pb-0 lg:px-8 md:pb-0">

            </div>

            <div className="grid mt-4 mb-4 lg:px-8 md:pb-0">

                <div className="col-12 lg:col-10 my-auto flex flex-column lg:align-items-start text-left lg:text-left" style={{ paddingTop: "20px" }}>

                    <h2 className="line-height-1 text-900 text-4xl font-normal" style={{ marginTop: "0px" }}>
                        Embracing Innovation: The Advantages of Harnessing Technology-Centered Clinical Research Software Startups
                    </h2>

                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 mt-4">
                        Harnessing new technology-centered clinical research software start-ups offers several advantages over using more established incumbents.
                    </span>

                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 mt-4">
                        Firstly, start-ups often bring fresh perspectives and innovative solutions to the table, unencumbered by legacy systems or outdated approaches. They have the agility to quickly adapt to evolving research needs and leverage cutting-edge technologies, such as artificial intelligence and machine learning, to drive efficiency and accelerate discoveries.
                    </span>

                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 mt-4">
                        Start-ups are typically more nimble and responsive to customer feedback. They have a customer-centric focus and are motivated to provide tailored solutions that address specific pain points in clinical research. By actively engaging with users, start-ups can iterate and refine their software rapidly, ensuring a more seamless user experience and addressing emerging challenges effectively.
                    </span>

                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 mt-4">
                        Start-ups are often more cost-effective options for organizations with limited budgets. They are often more flexible in pricing and offer competitive rates compared to established incumbents. This affordability factor is particularly beneficial for smaller research institutions or organizations aiming to allocate resources strategically while still harnessing the latest technological advancements.
                    </span>

                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 mt-4">
                        Furthermore, partnering with start-ups fosters a culture of collaboration and innovation. These companies are eager to establish themselves in the market and build strong relationships with customers. As a result, they are often more open to co-creation, customization, and integration with other research systems and tools. This collaboration mindset can lead to the development of tailored solutions that align precisely with the unique needs and workflows of research organizations.
                    </span>

                    <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 mt-4">
                        While established incumbents may offer a sense of stability and reliability, harnessing new technology-centered clinical research software start-ups brings forth a dynamic, forward-thinking approach. By embracing these start-ups, organizations can leverage their fresh perspectives, agility, cost-effectiveness, and collaborative mindset to drive transformative changes in the clinical research landscape.
                    </span>
                </div>



            </div>
        </div>

    </>)

}
