import { useNavigate } from 'react-router-dom'
import logo from './sections/images/clincove.svg'
export const Footer = () => {
    const nav = useNavigate();
    return (<>
        <div className="py-4 px-4 lg:px-8 mx-0 my-0 lg:mx-0" style={{ backgroundColor: "#F6F9FC" }}>
            <div id="footer" className="py-4 px-4 lg:px-8 mx-0 my-0 lg:mx-0" >
                <div className="grid justify-content-between">
                    <div className="col-12 md:col-3" style={{ marginTop: '7px' }}>
                        <div className="flex flex-wrap align-items-left justify-content-left md:justify-content-start md:mb-0 mb-3 cursor-pointer" style={{marginLeft: "-15px"}}>
                            <img alt="logo" class="logo_header" src={logo} />

                        </div>
                    </div>

                    <div className="col-12 md:col-10 lg:col-7">
                        <div className="grid text-left md:text-left">
                            <div className="col-12 md:col-3">
                                <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Products</h4>
                                <a onClick={() => {nav('/products/pilot'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Pilot</a>
                                <a onClick={() => {nav('/products/vista'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Vista</a>
                                <a onClick={() => {nav('/products/echo'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Echo</a>
                                <a onClick={() => {nav('/products/beacon'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Beacon</a>
                                <a onClick={() => {nav('/products/zenith'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Zenith</a>
                                <a onClick={() => {nav('/products/journey'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer text-700">Journey</a>
                            </div>

                            <div className="col-12 md:col-3 mt-4 md:mt-0">
                                <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Solutions</h4>
                                <a onClick={() => {nav('/solutions/sites'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Sites</a>
                                <a onClick={() => {nav('/solutions/cros'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">CROs</a>
                                <a onClick={() => {nav('/solutions/sponsors'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer text-700">Sponsors</a>
                            </div>

                            <div className="col-12 md:col-3 mt-4 md:mt-0">
                                <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Resources</h4>
                                <a onClick={() => {nav('/resources'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Guides</a>
                                <a onClick={() => {nav('/resources'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">White Papers</a>
                                <a onClick={() => {nav('/resources'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer text-700">Blog</a>
                            </div>

                            <div className="col-12 md:col-3 mt-4 md:mt-0">
                                <h4 className="font-medium text-2xl line-height-3 mb-3 text-900">Company</h4>
                                <a onClick={() => {nav('/about-us'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">About Us</a>
                                <a onClick={() => {nav('/jobs'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Jobs</a>
                                <a onClick={() => {nav('/newsroom'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Newsroom</a>
                                <a onClick={() => {nav('/legal'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Legal</a>
                                <a onClick={() => {nav('/privacy-policy'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Privacy Policy</a>
                                <a onClick={() => {nav('/contact-us'); window.location.reload(); }} className="line-height-3 text-xl block cursor-pointer mb-2 text-700">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)

}