import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mdiFlask, mdiGoogleAnalytics, mdiNeedle } from '@mdi/js'
import Icon from '@mdi/react'
import { useNavigate } from 'react-router-dom'

import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';

export const Why_Clincove = () => {
    const nav = useNavigate();
    return (
        <>
            <div style={{ backgroundColor: "white", paddingTop: "75px", clipPath: "polygon(0% 7%, 100% 0%, 100% 0%, 100% 100%, 0% 100%)" }}>
                <div className="py-4 px-0 2xl:px-8 mx-0 my-0 lg:mx-0" style={{ backgroundColor: "white" }}>
                    <div id="highlights" className="py-1 px-4 lg:px-8 mx-0 my-0 lg:mx-0" >
                        <div className="grid mt-0 xl:px-8 pb-2 md:pb-2" >
                            <div className="col-12 lg:col-8 my-auto flex flex-column text-left lg:text-left lg:align-items-start" >

                                <h2 className="line-height-1 text-700 font-normal purple mb-0" class="purple">Why Clincove</h2>
                                <h2 className="line-height-1 text-900 text-4xl mt-0" class="black" style={{ marginTop: "0px" }}>A technology-first approach to clinical trial management</h2>

                            </div>
                        </div>
                        <div className="grid mt-0 xl:px-8 justify-content-center">

                            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-0 mt-0 lg:mt-0">
                                <div
                                    style={{
                                        height: '200px',
                                        paddingTop: "14px",
                                        paddingRight: "14px",
                                        paddingBottom: "14px",
                                        paddingLeft: "0px",
                                        borderRadius: '10px',
                                        backgroundColor: "white"
                                    }}
                                >
                                    <div className="p-0 h-5" style={{ borderRadius: '8px', paddingLeft: "0px" }}>
                                        <div className="flex align-items-center justify-content-center mb-3" style={{ backgroundColor: "#F6F9FC", width: '3.5rem', height: '3.5rem', borderRadius: '10px' }}>
                                            <Icon size={1.5} color={'black'} path={mdiNeedle} />
                                        </div>
                                        <h5 class="white" className="mb-2 text-900">Embracing a Technology-First Approach</h5>
                                        <span className="text-600">Discover how a technology-first approach is reshaping clinical research, revolutionizing processes, and accelerating advancements in healthcare.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-0 mt-0 lg:mt-0">
                                <div
                                    style={{
                                        height: '200px',
                                        paddingTop: "14px",
                                        paddingRight: "14px",
                                        paddingBottom: "14px",
                                        paddingLeft: "0px",
                                        borderRadius: '10px',
                                        backgroundColor: "white"
                                    }}
                                >
                                    <div className="p-0 h-full" style={{ borderRadius: '8px', padding: "14px" }}>
                                        <div className="flex align-items-center justify-content-center mb-3" style={{ backgroundColor: "#F6F9FC", width: '3.5rem', height: '3.5rem', borderRadius: '10px' }}>
                                            <i className="fas fa-network-wired"></i>
                                            <FontAwesomeIcon style={{ fontSize: "1.5rem", color: "black" }} icon={faNetworkWired} />
                                        </div>
                                        <h5 class="white" className="mb-2 text-900">Optimizing Efficiency and Outcomes</h5>
                                        <span class="white" className="text-600">Explore how embracing technology in clinical research improves efficiency, enables data-driven decision-making, and enhances patient outcomes.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-0 mt-0 lg:mt-0">
                                <div
                                    style={{
                                        height: '200px',
                                        paddingTop: "14px",
                                        paddingRight: "14px",
                                        paddingBottom: "14px",
                                        paddingLeft: "0px",
                                        borderRadius: '10px',
                                        backgroundColor: "white"
                                    }}
                                >
                                    <div className="p-0 h-full" style={{ borderRadius: '8px', padding: "14px" }}>
                                        <div className="flex align-items-center justify-content-center mb-3" style={{ backgroundColor: "#F6F9FC", width: '3.5rem', height: '3.5rem', borderRadius: '10px' }}>
                                            <Icon size={1.5} color={'black'} path={mdiGoogleAnalytics} />
                                        </div>
                                        <h5 class="white" className="mb-2 text-900">Leveraging Technology for Enhanced Analysis</h5>
                                        <span class="white" className="text-600">Learn how the integration of technology in clinical trials streamlines data management, analysis, and regulatory compliance for more robust research.</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="grid lg:mt-4 xl:px-8 pb-0 md:pb-0" >

                            <div className="col-12 md:col-12 lg:col-8 p-0 lg:pr-5 lg:pb-5 mt-0 lg:mt-0 flex-order-2 lg:flex-order-1">
                                <div
                                    style={{
                                        height: '200px',
                                        padding: '0px',
                                        borderRadius: '10px',
                                        backgroundColor: "white",
                                        paddingBottom: "20px",
                                        display: "inline-block"
                                    }}
                                >
                                    <div className="p-0 h-full" style={{ borderRadius: '8px', padding: "14px" }}>
                                        <h2 className="line-height-1 text-900 text-4xl mb-5" style={{paddingBottom: "0.5rem"}} class="black">Ready to get started?</h2>
                                        <div className="text-700 text-xl line-height-3 mr-0 md:mr-2" style={{margin: "1.5rem 0 1rem 0"}}>
                                        <span  >
                                            Experience streamlined trial management, efficient data collection, real-time analytics, and enhanced collaboration, empowering your research team to achieve breakthroughs faster and more effectively. Utilize our comprehensive CLINCOVE Clinical Trial OS suite of software solutions to streamline your processes, enhance data management, and ensure regulatory compliance. Get started today and revolutionize your research.
                                        </span>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-0 mt-0 lg:mt-0 flex-order-1">
                                <div
                                    style={{
                                        height: '200px',
                                        paddingTop: "14px",
                                        paddingRight: "14px",
                                        paddingBottom: "14px",
                                        paddingLeft: "0px",
                                        borderRadius: '10px',
                                        backgroundColor: "white"
                                    }}
                                >
                                    <div className="p-0 h-full" style={{ borderRadius: '8px', padding: "14px" }}>
                                        <div className="flex align-items-center justify-content-center mb-3" style={{ backgroundColor: "#F6F9FC", width: '3.5rem', height: '3.5rem', borderRadius: '10px' }}>
                                            <Icon size={1.5} color={'black'} path={mdiFlask} />
                                        </div>
                                        <h5 class="white" className="mb-2 text-900">Cutting-Edge Technologies for Breakthrough Discoveries</h5>
                                        <span class="white" className="text-600">Uncover the transformative potential of cutting-edge technologies in driving innovation, unlocking new discoveries, and transforming the landscape of clinical research.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-12 lg:col-8 p-0 lg:pr-5 lg:pb-5 mt-0 lg:mt-0 flex-order-3">
                                <div
                                    style={{
                                        height: '200px',
                                        padding: '0px',
                                        borderRadius: '10px',
                                        backgroundColor: "white",
                                        paddingBottom: "20px",
                                        display: "inline-block"
                                    }}
                                >
                                    <div className="p-0 mt-4 h-full" style={{ borderRadius: '8px' }}>
                                        <button onClick={() => window.location.href = 'mailto:info@clincove.com'} aria-label="Get Started" style={{ backgroundColor: "black", marginRight: "20px" }} class="p-button p-component text-xl border-none mt-0 bg-black-500 font-normal line-height-3 px-3 text-white" type="button" data-pc-name="button" data-pc-section="root">
                                            <span class="p-button-label p-c" data-pc-section="label">
                                                Book a Demo
                                            </span>
                                        </button>
                                        <button onClick={() => nav('/contact-us')} aria-label="Get Started" style={{ backgroundColor: "white", color: "black" }} class="p-button p-component text-xl border-none mt-0 bg-black-500 font-normal line-height-3 px-3 text-black" type="button" data-pc-name="button" data-pc-section="root" >
                                            <span class="p-button-label p-c" data-pc-section="label">
                                                Streamline Your Trial {' '}
                                                <i className='pi pi-arrow-right'></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            
                            </div>

                        </div>





                    </div>
                </div>
            </div>
        </>
    )
}