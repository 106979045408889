import React, { useState, useEffect, useMemo, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import emailjs from 'emailjs-com';
import { Toast } from 'primereact/toast';



export const ContactForm = () => {
    const toast = useRef(null);
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [formErrors, setFormErrors] = useState({});

    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Success', detail:'Message sent successfully', life: 3000});
    }

    const showError = () => {
        toast.current.show({severity:'error', summary: 'Error', detail:'Error! Please try again', life: 3000});
    }


    const validate = () => {
        let errors = {};
        if (!formData.name) errors.name = 'Name is required';
        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is invalid';
        }
        if (!formData.message) errors.message = 'Message is required';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (validate()) {

            console.log('Sending email with:', formData);

            emailjs.sendForm('service_ysk2ubp', 'template_qv2x6xg', e.target, 'zMn2S7wqwCKsSMzZl')
                .then((result) => {
                    console.log(result.text);
                    showSuccess()
                                        // Handle success (e.g., show a success message)
                }, (error) => {
                    console.log(error.text);
                    showError()
                    // Handle errors (e.g., show an error message)
                });
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8" style={{ backgroundColor: "#F6F9FC" }}>


                <div className="grid mt-0 lg:px-8 pb-2 md:pb-8" >

                    <div className="col-12 lg:col-6 flex flex-column text-left lg:text-left lg:align-items-start" >
                        <div className="col-12 lg:col-10 flex flex-column text-left lg:text-left lg:align-items-start" >

                            <h2 className="line-height-1 text-900 text-4xl font-normal mt-0">Contact Us</h2>

                            <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 mt-4">
                                Please contact us directly with any questions, comments, or scheduling inquiries you may have.
                            </span>
                            <span class="purple" className="text-700 text-xl line-height-3 mr-0 md:mr-2 mt-4" >
                                You can reach us at info@clincove.com
                            </span>
                        </div>
                    </div>

                    <div className="col-12 lg:col-6 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                        <div className="col-12 lg:col-10">
                            <div className="card p-fluid">
                                <form onSubmit={handleSubmit}>
                                    <div className="field">
                                        <label htmlFor="name">Name</label>
                                        <input className="p-inputtext p-component" id="name" name="user_name" value={formData.name} onChange={handleChange} type="text" />
                                        {formErrors.name && <span className="error-message">{formErrors.name}</span>}
                                    </div>
                                    <div className="field">
                                        <label htmlFor="email">Email</label>
                                        <input className="p-inputtext p-component" id="email" name="user_email" value={formData.email} onChange={handleChange} type="text" />
                                        {formErrors.email && <span className="error-message">{formErrors.email}</span>}
                                    </div>

                                    <div className="field">
                                        <label htmlFor="message">Message (*Required)</label>
                                        <textarea
                                            className="p-inputtext p-component"
                                            id="message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            rows="4"
                                            style={{
                                                width: '100%', // Set the width as needed
                                                resize: 'vertical' // Allows vertical resizing only
                                            }}
                                        ></textarea>
                                        {formErrors.message && <span className="error-message">{formErrors.message}</span>}
                                    </div>
                                    <button className="p-button p-component text-xl border-none mt-3 font-normal line-height-3 px-3 text-white" type="submit" style={{ backgroundColor: "#6200EE", borderRadius: "52px" }}>
                                        Send <i className='pi pi-arrow-right'></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>




            </div>

            <div>




            </div>



        </>
    )
}