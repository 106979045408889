import { useNavigate } from 'react-router-dom'
import hero from './images/hero.png'
export const HeroNoText = () => {
    const nav = useNavigate();
    return(
    <div id="hero" className="py-4 px-4 lg:px-1 2xl:px-8 mx-0 my-0 lg:mx-8">
            <div className="grid mt-0 lg:px-8 pb-2 md:pb-8">
                <div className="col-12 lg:col-8 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                    <h2 className="line-height-1 text-900 text-4xl font-normal">AI-powered clinical trial OS, for the modern clinical trial</h2>
                   
                    <div style={{marginBottom: "10px", paddingTop: "40px"}}>
                        <button  onClick={() => window.location.href = 'mailto:info@clincove.com'} aria-label="Get Started" style={{ backgroundColor: "black", marginRight: "20px" }} class="p-button p-component text-xl border-none mt-3 bg-black-500 font-normal line-height-3 px-3 text-white" type="button" data-pc-name="button" data-pc-section="root">
                            <span class="p-button-label p-c" data-pc-section="label">
                                Book a Demo
                            </span>
                        </button>
                        <button   onClick={()=> nav('/contact-us')} aria-label="Get Started" style={{ backgroundColor: "white", color: "black" }} class="p-button p-component text-xl border-none mt-3 bg-black-500 font-normal line-height-3 px-3 text-black" type="button" data-pc-name="button" data-pc-section="root">
                            <span class="p-button-label p-c" data-pc-section="label">
                                Streamline Your Trial {' '}
                                <i className='pi pi-arrow-right'></i>
                            </span>
                        </button>
                    </div>
                </div>

             
            </div>
        </div>
    )
}