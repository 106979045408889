import background1 from '../sections/images/Background1.png'
import 'boxicons/css/boxicons.min.css';
import Icon from '@mdi/react';
import { mdiPill, mdiScaleBalance } from '@mdi/js';
import { useNavigate } from 'react-router-dom';


export const WhitePaper = () => {
    const nav = useNavigate();
    return (
        <>
             <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8" style={{ backgroundColor: "#6200EE", border: "0px solid" }}>

                <div id="highlights" className="py-1 lg:px-4 lg:px-8 mx-0 my-0 lg:mx-0" >
                    <div className="grid mt-4 pb-2 md:pb-2" >
                        <div className="col-12 lg:col-8 my-auto flex flex-column text-left lg:text-left lg:align-items-start" >
                            <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 text-4xl green" >
                                <span className='green text-4xl'>White Paper</span>

                            </span>
                            <h2 className="line-height-1 text-900 text-4xl" class="white">Revolutionizing Clinical Trials: Harnessing the Power of AI for Data-driven Insights and Breakthrough Discoveries</h2>
                            <span className="text-700 text-xl line-height-3 mr-0 md:mr-2" class="white" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                                <span className='white text-xl'> This white paper explores the transformative impact of AI-driven clinical trials, revolutionizing the landscape of healthcare research. By harnessing the power of artificial intelligence, data-driven insights are generated, accelerating the discovery of new treatments and improving patient outcomes. We delve into the applications of AI in study design, patient recruitment, data analysis, and personalized medicine, highlighting the potential for enhanced efficiency, cost-effectiveness, and precision in clinical trials. Discover how AI-driven clinical trials are paving the way for a new era of innovation and advancement in the field of healthcare.

                                </span>
                            </span>
                            <button onClick={() => nav('/contact-us')} style={{ borderRadius: "52px", backgroundColor: "#03DAC6", color: "black", marginBottom: "10px" }} aria-label="Get Started" class="black" className="p-button p-component text-xl border-none mt-3 font-normal line-height-3 px-3" type="button" data-pc-name="button" data-pc-section="root">
                                <span class="p-button-label p-c" data-pc-section="label">
                                    Get in Touch{' '}
                                    <i className='pi pi-arrow-right'></i>
                                </span>
                            </button>
                        </div>


                    </div>










                </div>

            </div >

        </>
    )
}