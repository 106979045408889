import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mdiFlask, mdiGoogleAnalytics, mdiNeedle } from '@mdi/js'
import Icon from '@mdi/react'

import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export const Blog = () => {
    const nav = useNavigate();
    return (
        <>
            <div id="hero" className="py-4 px-4 xl:px-8 mx-0 my-0 2xl:mx-8" style={{ backgroundColor: "white", paddingTop: "100px", clipPath: "polygon(0% 15%, 100% 0%, 100% 0%, 100% 100%, 0% 100%)" }}>

                <div className="grid mt-8 lg:px-8 pb-2 md:pb-2" >
                    <div className="col-12 lg:col-8 my-auto flex flex-column text-left lg:text-left lg:align-items-start" >
                        <span className="text-700 text-xl line-height-3 mr-0 md:mr-2 text-4xl green" >
                            <span className='purple text-4xl'>Blog</span>

                        </span>
                        <h2 className="line-height-1 text-900 text-4xl" class="black" style={{ paddingBottom: "40px" }}>Embracing Innovation: The Advantages of Harnessing Technology-Centered Clinical Research Software Start-ups</h2>
                        <button onClick={() => nav('/contact-us')} style={{ borderRadius: "52px", backgroundColor: "#03DAC6", color: "black", marginBottom: "10px", marginTop: "80px" }} aria-label="Get Started" class="black" className="p-button p-component text-xl border-none mt-3 font-normal line-height-3 px-3" type="button" data-pc-name="button" data-pc-section="root">
                            <span class="p-button-label p-c" data-pc-section="label">
                                Get in Touch{' '}
                                <i className='pi pi-arrow-right'></i>
                            </span>
                        </button>
                    </div>
                </div>





            </div>
        </>
    )
}