import React, { useContext, useRef, useState } from 'react';


import background2 from '../sections/images/Background2.png'
import background3 from '../sections/images/Background4.png'

import hero from "../sections/images/hero.png"
import { StyleClass } from 'primereact/styleclass';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Hero } from '../sections/hero';
import { Unified_Platform } from '../sections/unified-platform';
import { Security_Privacy } from '../sections/security-privacy';
import { Why_Clincove } from '../sections/why-clincove';



export const Home = () => {

    return (<>

        <Hero />
        <div style={{ backgroundColor: "#3700B3", border: "0px" }}>
            <Unified_Platform />
        </div>
        <div style={{ border: "black 0px solid" }}>
            <Security_Privacy />
        </div>

        <div style={{ backgroundColor: "#3700B3", borderBottom: "1px solid white" }}>
            <Why_Clincove />
        </div>








    </>)

}
