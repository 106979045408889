import { useNavigate } from "react-router-dom"

export const Family = () => {
    const navigate = useNavigate();
    return (<>
        <div className="py-4 px-4 lg:px-8 mx-0 my-0 lg:mx-0 " style={{ backgroundColor: "#F6F9FC", borderTopColor: "white", borderTop: "1px solid white" }}>

            <div id="hero" className="py-4 px-4 lg:px-8 mx-0 my-0 lg:mx-0" style={{ zIndex: 2 }}>
                <div className="grid mt-0 lg:px-8 pb-2 md:pb-8" >
                    <div className="col-12 lg:col-7 my-auto flex flex-column text-left lg:text-left lg:align-items-start">

                        <h2 className="line-height-1 text-900 text-4xl font-normal">
                            Please enjoy the presentation attached within the link below.</h2>
                    </div>

                   
                    <div className="col-12 lg:col-6 my-auto flex flex-column text-left lg:text-left lg:align-items-start">
                        <div>

                            <button onClick={()=>window.location.href ="https://www.canva.com/design/DAFpw_07sJA/wCg4ULF4Y9xWiOyWiGn8FQ/view?utm_content=DAFpw_07sJA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#1"} aria-label="Get Started" class="p-button p-component text-xl border-none mt-3 font-normal line-height-3 px-3 text-white" type="button" data-pc-name="button" data-pc-section="root" style={{ backgroundColor: "#6200EE", borderRadius: "52px", padding: "20px" }}  >
                                <span class="p-button-label p-c" data-pc-section="label">
                                   Clincove F&F Deck {' '}
                                    <i className='pi pi-arrow-right'></i>
                                </span>


                            </button>

                        </div>

                    </div>


                </div>
            </div>
        </div>
    </>)
}
