import './App.css';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './components/pages/home';
import { Products_Page } from './components/pages/products-page';
import { Product_Page } from './components/pages/product-page';
import { Resources } from './components/pages/resources';
import { Jobs } from './components/pages/jobs';
import { AboutUs } from './components/pages/about-us';
import { Legal } from './components/pages/legal';
import { ContactUs } from './components/pages/contact-us';
import { NewsroomPage } from './components/pages/newsroom';
import { Introduction } from './components/pages/introduction';
import { FFD } from './components/pages/family-and-friends-deck';
import { InvestorUpdate } from './components/pages/investor-update';
import { Solution_Page } from './components/pages/solution-page';
import { Blog } from './components/pages/blog';
import { NotFound } from './components/pages/404';
import { Privacy_page, privacy_page } from './components/pages/privacy_page';
import { Privacy } from './components/sections/privacy';


function App() {
  return (

    <>


      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/family-and-friends-deck" element={<FFD />} />
          <Route path="/investor-update" element={<InvestorUpdate />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy-policy" element={<Privacy_page />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/newsroom" element={<NewsroomPage />} />
          <Route path="/products/:product_name" element={<Product_Page />} />
          <Route path="/solutions/:solution_name" element={<Solution_Page />} />
          <Route path="/404" element={<NotFound />} />
          <Route path='*' element={<NotFound />} />


        </Routes>
        <Footer />
      </BrowserRouter>


    </>


  );
}

export default App;
